<template>
  <div>
    <template v-for="(taskSnackBar, index) in taskSnackBars">
      <v-snackbar
        :key="'snackbar-task-' + taskSnackBar.task.id"
        v-model="taskSnackBars[index]"
        multi-line
        :color="taskSnackBar.color"
        :timeout="taskSnackBar.timeout"
        right
        shaped
        top
      >
        <v-progress-circular
          v-if="taskSnackBar.loading"
          indeterminate
          :size="20"
          :width="2"
        />
        {{ taskSnackBar.text }}
        <TaskLink
          v-if="taskSnackBar.task"
          :task="taskSnackBar.task"
        />

        <template v-slot:action>
          <v-btn
            icon
            color="white"
            @click="close(taskSnackBar.task.id)"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import TaskLink from '@/components/base/Link/Task.vue'

export default {
  name: 'TasksStatus',
  components: { TaskLink },
  data() {
    return {
      tasks: [],
    }
  },
  computed: {
    taskSnackBars () {
      return this.tasks
        .filter(t => !!t?.id)
        .map(task => ({
          color: this.getColorStatus(task.status),
          text: this.$t(
            'tasks.status.' + task.status,
            { result: JSON.stringify(task.result), error: JSON.stringify(task.error) }
          ),
          link: '/admin/tasks/?id=' + task.id,
          loading: ['waiting', 'pending'].includes(task.status),
          timeout: ['finished', 'error'].includes(task.status) ? 2000 : -1,
          task,
        }))
    },
  },
  created () {
    EventBus.$on('TASK', (taskId) => {
      this.load(taskId)
    })
  },
  methods: {
    getColorStatus (status) {
      switch (status) {
      case 'finished':
        return 'green'
      case 'error':
        return 'red'
      default:
        return 'grey lighten-1'
      }
    },
    load (taskId) {
      this.tasks = [...this.tasks, { id: taskId, status: 'waiting', calls: 0 }]
      this.pullTask(taskId)
    },

    async pullTask (taskId, count = 0) {
      if (this.taskSnackBars.find(t => t?.task.id) === true) {
        return
      }
      const response = await this.$axios.get('tasks/' + taskId)

      let stop = false
      this.tasks = this.tasks.map(v => {
        if (v.id !== response.data.id) {
          return v
        }

        const data = response.data
        data.calls = v.calls + 1

        if (data.calls > 200) {
          stop = true

          return true
        }

        return data
      })

      if (stop) {
        return
      }

      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
      })

      return this.pullTask(taskId, count + 1)
    },
    close (taskId) {
      this.tasks = this.tasks.map(v => {
        if (taskId !== v.id) {
          return v
        }

        // same value as timeout because we have no control on timeout behavior
        return true
      })
    },
  },
}
</script>
